import React from 'react'
import ReactDOM from 'react-dom'
import App from './Components/App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()
