import React from 'react'
import Logo from './Logo'

function App() {
  return (
    <>
      <Logo />
      <h1>aleph.black</h1>
    </>
  )
}

export default App
