import React, { useState } from 'react'
import { Offline } from 'react-detect-offline'

function Logo() {
  const [strokeWidth, setStrokeWidth] = useState(2)
  const [curvePull, setCurvePull] = useState(36)
  const [verticalUnit, setVerticalUnit] = useState(12)
  const w = 100
  const tD = w + strokeWidth
  const hS = strokeWidth / 2.0
  const cY = parseInt(curvePull)
  const vU = verticalUnit
  const p0 = [0 + hS, w - 2 * vU + hS]
  const p1 = [0 + hS, w + hS]
  const p2 = [w / 3.0 + hS, w + hS]
  const p3 = [w / 3.0 + hS, w - vU + hS]
  const p4 = [0 + hS, vU * 2 + hS]
  const p5 = [0 + hS, 0 + hS]
  const p6 = [w / 3.0 + hS, 0 + hS]
  const p7 = [w / 3.0 + hS, vU + hS]
  const p8 = [(w * 2) / 3.0 + hS, w - 2 * vU + hS]
  const p9 = [(w * 2) / 3.0 + hS, w + hS]
  const p10 = [w + hS, w + hS]
  const p11 = [w + hS, w - 3 * vU + hS]
  const p12 = [(w * 2) / 3.0 + hS, 0 + hS]
  const p13 = [w + hS, 0 + hS]

  const path = `
    M ${p0[0]} ${p0[1]}
    L ${p1[0]} ${p1[1]}
    L ${p2[0]} ${p2[1]}
    L ${p3[0]} ${p3[1]}
    C ${p3[0]} ${p3[1] - cY}, ${p4[0]} ${p4[1] + cY}, ${p4[0]} ${p4[1]}
    L ${p5[0]} ${p5[1]}
    L ${p6[0]} ${p6[1]}
    L ${p7[0]} ${p7[1]}
    C ${p7[0]} ${p7[1] + cY}, ${p8[0]} ${p8[1] - cY}, ${p8[0]} ${p8[1]}
    L ${p9[0]} ${p9[1]}
    L ${p10[0]} ${p10[1]}
    L ${p11[0]} ${p11[1]}
    C ${p11[0]} ${p11[1] - cY}, ${p12[0]} ${p12[1] + cY}, ${p12[0]} ${p12[1]}
    L ${p13[0]} ${p13[1]}
  `
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="300"
        fill="none"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        viewBox={`0 0 ${tD} ${tD}`}
        strokeLinecap="square"
      >
        <path d={path} />
      </svg>
      <Offline>
        <div style={{ marginTop: 24 }}>
          <div style={{ margin: 6 }}>
            <input
              type="range"
              value={strokeWidth}
              onChange={(e) => setStrokeWidth(parseInt(e.target.value) || 1)}
              min="0"
              max="100"
            />
          </div>
          <div style={{ margin: 6 }}>
            <input
              type="range"
              min="0"
              max="100"
              value={curvePull}
              onChange={(e) => setCurvePull(parseInt(e.target.value || 1))}
            />
          </div>
          <div style={{ margin: 6 }}>
            <input
              type="range"
              min="0"
              max="100"
              value={verticalUnit}
              onChange={(e) => setVerticalUnit(parseInt(e.target.value || 1))}
            />
          </div>
        </div>
      </Offline>
    </>
  )
}

export default Logo
